.subscription-conatiner {
    width: 100%;
    box-sizing: border-box;
}

.sus-head {
    display: inline-block;
    white-space: nowrap;
    margin-left: 3%;
}


.cardborder{
    border: 1px solid #cac8cc;
    padding:3%;
    border-radius: 10px;
}
.sus-makers {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A4A99;
}

.sus-portal {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A4A99;

}

.sus-content {
    text-align: center;

}

.content-header {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;

}

.content-sub {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

}

.month{
 
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        color: #131315;
   
}

.sub-card {
    width: 100%;
    height: 60%;
    border-radius: 21.49px;
    border: 2px solid #D4D2E3;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
}

.suscard-header {
    font-family: Inter;
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: center;
    color: #5A4A99;
}

.sus-value {
    font-family: Inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0px;
    text-align: center;
    color: #5A4A99;

}

.sus-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A4A99;
}

.get {
    color: white;
    font-size: 11px;
    font-family: "Inter";
    font-weight: 400;
    background: #5A4A99;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    border: none;
    box-sizing: border-box;
    width: 65%;
    height: 34.15px;
    padding: 25.07px, 50.15px, 25.07px, 50.15px;
    border-radius: 42.33px;
    gap: 7.16px;
    margin-top: 10%;

}

.get-button {
    margin-top: 8%;
}

.suscard-row {
    margin-top: 3%;
    display: flex;
    margin-left: 1%;
    height: 418px ;
}

.month-header {
    width: 100%;
    height: 58px;
    padding: 2px;
}

.value_div {
    height: 56px;
    width: 100%;
}
.coupon-label{
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    
}
.coupon-btn{
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    width: 90px;
    background: rgb(5 39 19);
    border-radius: 12px;
    border: none;
    cursor: "Inter";
    height: 43px;
    outline: inherit;
    margin-right: 3%;
}
.payment_popup {
    position: absolute;
    top: 20%;
    right: 15%;
    background-color: #FFFFFF;
    padding: 23px;
    z-index: 1;
    width: 65%;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.planprice{
    display: flex;
}
.price{
    text-align: right;
}
.planheader{
    padding-bottom: 5%;

}
.field-coupon {
    border-radius: 10px;
    padding: 8px;
    border: 1px solid #cac8cc;
    width: 100%;
    color: #3E3636;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
}
.button-div{
        text-align: center;
        padding-top: 5%;
}
.coupon-div{
    text-align: center;
    margin-left: 4%;
}
.coupon{
    margin-bottom: 3%;
    padding-bottom: 7%;
    border-bottom: 1px solid #cac8cc;

}
.poptext{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 18px;
text-align: left;

}
.paid-btn {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    width: 90%;
    background: #73f93e;
    border-radius: 12px;
    border: none;
    cursor: "Inter";
    height: 35px;
    outline: inherit;
}
.planColumn{
    border-right: 0.94px solid #DED7D7;
    padding-right:4%

}
.cardColumn{
padding-left:4%
}
.pay-btn {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    width: 90%;
    background: #5A4A99;
    border-radius: 12px;
    border: none;
    cursor: "Inter";
    height: 35px;
    outline: inherit;


}

/* Custom CSS for CardElement */
.card-element {
    font-size: 16px;
    /* Adjust font size */
    padding: 12px;
    /* Adjust padding */
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.card-element:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cancel-btn {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    width: 90%;
    background: red;
    border-radius: 12px;
    border: none;
    cursor: "Inter";
    height: 35px;
    outline: inherit;
    margin-top: 2%;
    margin-right: 0%;
}

.pay-header {
    color: #000;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 6%;
}
.cardElement{
    padding-top: 2%;
}
.card-label{
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    padding-bottom: 5%;    
}
.buttton-div {
    text-align: right;
}

.close-icon{
    height:15px;
}
.trail-card{
    width: 100%;
    height: 60%;
    border-radius: 21.49px;
    border: 2px solid #D4D2E3;
background: #5A4A99;
    
    text-align: center;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 3px;
}
.trail-h{
        width: 100%;
        height: 58px;
        padding: 2px;
        margin-top: 15%;
}
.trail-header{
    font-family: Inter;
font-size: 13.47px;
font-weight: 700;
line-height: 18.52px;
text-align: center;
color: #FFFFFF;
}
.trial-value{
    font-family: Inter;
font-size: 11.78px;
font-weight: 400;
line-height: 16.83px;
text-align: center;
color: #FFFFFF;

}
.content{
    overflow-y: scroll;
    
}

.content::-webkit-scrollbar {
    display: none;
}
.trial-btn{
    color: white;
    font-size: 11px;
    font-family: "Inter";
    font-weight: 400;
    background: #5A4A99;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    box-sizing: border-box;
    width: 56%;
    border: 0.84px solid #FFFFFF;
    height: 34.15px;
    padding: 25.07px, 50.15px, 25.07px, 50.15px;
    border-radius: 42.33px;
    gap: 7.16px;

}
/* Hide scrollbar for IE, Edge and Firefox */
.content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 600px) {
    .sus-head {
        display: inline-block;
        white-space: nowrap;
        margin-top: 22%;
        margin-left: 0%;

    }

    .sus-makers {
        font-family: Poppins;
        font-size: 23px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A4A99;
    }

    .sus-portal {
        font-family: Poppins;
        font-size: 23px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A4A99;
    }

    .content-header {
        font-family: Inter;
        font-size: 23px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: center;
    }

    .suscard-row {
        margin-top: 1%;
        display: grid;
        margin-left: -32%;
        height: 100%;
        margin-bottom: 3%;
    }

    .sus-content {
        text-align: center;
        margin-top: 13%;
    }

    .content-sub {
        font-family: Inter;
        font-size: 9px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
    }

    .sub-card {
        width: 249.45px;
        height: 248.63px;
        border-radius: 21.49px;
        border: 2px solid #D4D2E3;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 19px;
        margin-left: 35%;
    }
    .trail-card {
        width: 250px;
        height: 250px;
        border-radius: 21.49px;
        border: 2px solid #D4D2E3;
        background: #5A4A99;
        text-align: center;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 3px;
        margin-left: 35%;

    }

    .suscard-header {
        font-family: Inter;
        font-size: 17px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        color: #5A4A99;
    }

    .sus-value {
        font-family: Inter;
        font-size: 29px;
        font-weight: 700;
        line-height: 59px;
        letter-spacing: 0px;
        text-align: center;
        color: #5A4A99;
    }
    .planColumn {
        border-bottom: 0.94px solid #DED7D7;
        padding-right: 4%;
    }
   
    .sus-text {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #5A4A99;
    }

    .get {
        color: white;
        font-size: 14px;
        font-family: "Inter";
        font-weight: 400;
        background: #5A4A99;
        align-items: center;
        gap: 16px;
        text-decoration: none;
        border: none;
        box-sizing: border-box;
        width: 133.81px;
        height: 35.15px;
        padding: 25.07px, 50.15px, 25.07px, 50.15px;
        border-radius: 42.33px;
        gap: 7.16px;
    }

    .suscard-class {
        margin-top: 5%
    }

    .payment_popup {
        position: absolute;
        top: 16%;
        right: 4%;
        background-color: #FFFFFF;
        padding: 23px;
        z-index: 1;
        width: 93%;
        height: auto;
        box-sizing: border-box;
        border-radius: 10px;
        display: grid;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .planheader {
        padding-bottom: 0%;
    }
    .card-label {
        font-family: Inter;
        font-size: 13px;
        font-weight: 700;
        line-height: 19.36px;
        padding-bottom: 2%;
        text-align: left;
    }
    .coupon-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
}
.field-coupon {
    border: 1px solid #cac8cc;
    border-radius: 10px;
    color: #3e3636;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    padding: 8px;
    width: 100%;
    height: 20%;
}
.coupon-btn {
    background: #052713;
    border: none;
    border-radius: 12px;
    color: #fff;
    cursor: "Inter";
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    height: 97%;
    margin-right: 3%;
    outline: inherit;
    text-align: center;
    width: 90px;
}

.poptext {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}
.pay-header {
    color: #000;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0%;
}
    .cardColumn {
        padding-left: 4%;
        margin-top: 3%;
    }
    .button-div {
        text-align: center;
        padding-top: 3%;
    }
   
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
    .sus-value {
        font-family: Inter;
        font-size: 23px;
        font-weight: 700;
        line-height: 59px;
        letter-spacing: 0px;
        text-align: center;
        color: #5A4A99;
    }
    .suscard-row {
        margin-top: 3%;
        display: flex;
        margin-left: 1%;
        height: 631px;
    }
    .get {
        color: white;
        font-size: 10px;
        font-family: "Inter";
        font-weight: 400;
        background: #5A4A99;
        align-items: center;
        gap: 16px;
        text-decoration: none;
        border: none;
        box-sizing: border-box;
        width: 50%;
        height: 36.15px;
        padding: 25.07px, 50.15px, 25.07px, 50.15px;
        border-radius: 6.33px;
        gap: 7.16px;

    }
   
  
    .sub-card {
        width: 100%;
        height: 239.63px;
        border-radius: 21.49px;
        border: 2px solid #D4D2E3;
        text-align: center;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

    }
    .trail-card {
        width: 100%;
        height: 239px;
        border-radius: 21.49px;
        border: 2px solid #D4D2E3;
        background: #5A4A99;
        text-align: center;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 3px;

    }
    .trail-header {
        font-family: Inter;
        font-size: 11.47px;
        font-weight: 700;
        line-height: 18.52px;
        text-align: center;
        color: #FFFFFF;
    }
    .trial-value {
        font-family: Inter;
        font-size: 10.78px;
        font-weight: 400;
        line-height: 16.83px;
        text-align: center;
        color: #FFFFFF;
    }
    .get-button {
        margin-top: 18%;
    }
    .sus-content {
        text-align: center;
        margin-top: 32%;
    }
    .links1 {
        color: var(--white-100, #FFF);
        text-decoration: none;
        font-family: Inter;
        font-size: 11px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        border: none;
        margin-left: 60%;
        margin-top: 2%;
    }
    
}

@media (min-width: 768px) and (max-width: 768px) {
    .sus-head {
        display: inline-block;
        white-space: nowrap;
        margin-left: 3%;
        margin-top: 13%;
    }
    .suscard-row {
        margin-top: 3%;
        display: flex;
        margin-left: 1%;
        height: 426px;
    }
    .suscard-header {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        color: #5A4A99;
    }
    .sus-value {
        font-family: Inter;
        font-size: 23px;
        font-weight: 700;
        line-height: 59px;
        letter-spacing: 0px;
        text-align: center;
        color: #5A4A99;
    }
    .get {
        color: white;
        font-size: 10px;
        font-family: "Inter";
        font-weight: 400;
        background: #5A4A99;
        align-items: center;
        gap: 16px;
        text-decoration: none;
        border: none;
        box-sizing: border-box;
        width: 93%;
        height: 36.15px;
        padding: 25.07px, 50.15px, 25.07px, 50.15px;
        border-radius: 6.33px;
        gap: 7.16px;
    }
    .sub-card {
        width: 117%;
        height: 239.63px;
        border-radius: 21.49px;
        border: 2px solid #D4D2E3;
        text-align: center;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .sus-content {
        text-align: center;
        margin-top: 24%;
    }
    .suscard-class{
        margin-left:1%
    }
}