.dashboard-header {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}
.orderLink{
  
  text-align: center;
  padding:12px;
}
.card {
  height: 102px;
  width: 197px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 0.525px dashed #A69797;
  background: var(--white-100, #FFF);
  padding: 2px;
  margin-right: 4%;
  margin-top: 3%;
}

.row-header {
  height: 60px;
}

.dash-icons {
  width: 32px;
  height: 32px;
}

.value {
  color: #3F3F3F;
  font-family: Inter;
  font-size: 23.905px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-text {
  color: #696161;
  font-family: Inter;
  font-size: 14.222px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.icon-container {


  width: 32px;
  height: 32px;

}

.text-cont {
  width: 106px;
  height: 46px
}

.card-row {
  padding: 2px
}

.sub-header {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tab-header {
  margin-top: 3%
}

.tab {
  margin-top: 3%;
  width: 97%
}

.graph-row {
  margin-top: 2%;
  
  display: flex;
  flex-direction: row;
   width: 100%;
   flex-wrap: wrap;
}

.graph1 {
  border-radius: 17px;
  background: var(--white-100, #FFF);
  height: 400px;
  flex-grow: 1; /* Grow to fill available space */
 
  flex-shrink: 0;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 28px;
  margin-top: 3%;

}

.graph2 {
  border-radius: 17px;
  background: var(--white-100, #FFF);
  
  height: 95%;
  width:400px;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 28px;
  margin-top: 3%;
  overflow-x: scroll;

 

}

.graph-header {
  color: #736E6E;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

}

.first-graph {
  margin-top: 7%;
  margin-left: 17%;

  height: 300px;
   
    width: 300px 
}

.second-graph {
  margin-top: 7%;
  margin-left: 15%;

  height: 271px;
  width: 300px 

}
.no-information{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top:43%

}
.no-information1{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
margin-top: 27%;


}
.no-date{
  width: 100%;
height: 286px;
border-radius: 20px;
border: 1px dashed #B39D9D;
text-align: center;
padding:30px;

}
.tab-in{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
justify-content: center;
margin-top:5%;

}
.gobutton {
  color: white;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  height: 100%;
  width: 200px;
 padding:8px;
  background: #484444;
  border-radius: 12px;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  border:none;
  box-sizing: border-box;
}
.btn-div{
  margin-top:2%;
  text-align:center
}
@media only screen and (max-width: 768px) {
  .no-date {
    width: 100%;
    height: 286px;
    border-radius: 20px;
    border: 2px dashed #B39D9D;
    text-align: center;
    padding: 30px;
}
  .dashboard {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 25px;
    width: 100%;
  }

  .row-header {
    height: 31px;
    margin-top: 17%;
  }

  .dashboard-header {
    color: #000;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tab {
    margin-top: 3%;
    width: 100%;
    overflow: scroll;
  }

  .card {
    height: 77px;
    width: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 2px dashed #A69797;
    background: var(--white-100, #FFF);
    padding: 2px;
    margin-top: 4%;

  }

  .value {
    color: #3F3F3F;
    font-family: Inter;
    font-size: 12.905px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .card-text {
    color: #696161;
    font-family: Inter;
    font-size: 9.222px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .sub-header {
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .first-graph {
    height: 224px;
    margin-left: 2%;
    width: 277px;
}

  .second-graph {
    margin-top: 14%;
    height: 68%;
    width: 297px;
  }

  .graph2 {
    border-radius: 17px;
    background: var(--white-100, #FFF);
    width: 484px;
    height: 321px;
    flex-shrink: 0;
    box-sizing: border-box;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 28px;
    margin-top: 8%;
    overflow-x: scroll;
    }

  .graph1 {
    border-radius: 17px;
    background: var(--white-100, #FFF);
    width: 484px;
    height: 321px;
    flex-shrink: 0;
    box-sizing: border-box;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 28px;
    margin-top: 8%;

  }

  .card-row {
    margin-left: 2%;
    margin-top: 5%;

  }

  .dash-icons {
    width: 20px;
    height: 20px;
  }

  .text-cont {
    width: 51px;
    height: 33px;
  }

  .tab-header {
    margin-top: 6%;
  }
  .graph-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2%;
    margin-left: 1%;
    width: 100%;
}
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
  .first-graph {
    height: 266px;
    margin-left: 10%;
    width: 282px 

}
.no-information1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 45%;
}
.first-graph {
  margin-top: 7%;
  height: 266px;
  margin-left: 0%;
  width: 282px;
}
.second-graph {
  margin-top: 14%;
  margin-left: 2%;
  height: 227px;
  width: 250px 
}
.graph1 {
  border-radius: 17px;
  background: var(--white-100, #FFF);
  height: 95%;
  flex-grow: 1; /* Grow to fill available space */
 
  flex-shrink: 0;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 28px;
  margin-top: 3%;

}

.graph2 {
  border-radius: 17px;
  background: var(--white-100, #FFF);
  height: 95%;
  width: 239px;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 28px;
  margin-top: 3%;
  overflow-x: scroll;

 

}
}
@media (min-width: 768px) and (max-width: 768px){
  .graph1 {
    border-radius: 17px;
    background: var(--white-100, #FFF);
    height: 95%;
    width: 34%;
    flex-grow: 1;
    flex-shrink: 0;
    box-sizing: border-box;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 28px;
    margin-top: 3%;
}
}