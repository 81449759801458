.index {
  align-items: center;
  background-color: #fff;
  border-color: #D3CECE;
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex-direction: column;
height: 100%;
  gap: 48px;
  width:90%;
  padding: 138px 30px 123px;
  box-sizing: border-box;
  position: relative;
}
.anounce-pop {
  position: absolute;
  top: 8%;
  right: 20%;
  background-color: #F9FAFC;
  padding: 23px;
  z-index: 1;
  width: 48%;
  height: auto;
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  border: 0.5px solid #D7DAE0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p;
  padding: 20px;
}
.index .frame-2 {
  flex: 0 0 auto;
  position: relative;
}

.index .div-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  width: 260px;
  /* background-color: green; */
}

.index .frame-3 {
  align-items: flex-start;
  border-radius: 24px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  padding: 16px 187px 16px 24px;
  position: relative;
  width: 260px;
}

.index .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 31px;
  justify-content: center;
  margin-right: -49px;
  position: relative;
}

.index .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.index .text-wrapper {
  color: #636060;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}



.index .frame-instance {
  margin-right: -57px !important;
}

.index .frame-51-instance {
  left: 0 !important;
  position: absolute !important;
  top: 160px !important;
}

.index .design-component-instance-node {
  margin-right: -62px !important;
}

.index .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 240px;
  width: 260px;
}

.index .frame-6 {
  align-items: flex-start;
  border-radius: 24px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  margin-right: -7px;
  padding: 16px 187px 16px 24px;
  position: relative;
  width: 267px;
}

.index .frame-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 31px;
  margin-right: -60px;
  position: relative;
}

.index .vector {
  height: 16px;
  position: relative;
  width: 16px;
}

.index .text-wrapper-2 {
  color: #7b7b7b;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.index .frame-8 {
  left: 30px !important;
  position: absolute !important;
  top: 458px !important;
}

.index .frame-9 {
  margin-right: -49px !important;
}

.index .logo {
  height: 26px;
    left: 18%;
    position: absolute;
    top: 5%;
    width: 100%;
}

.index .text {
  height: 26px;
  position: relative;
  width: 210px;
}

.index .MAKERS-PORTAL {
  color: #2b3674;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.index .span {
  font-weight: 700;
}

.index .text-wrapper-3 {
  color: #2b3674;
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
}
.sidebar {
  width: 200px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -200px;
  background-color: #333;
  transition: left 0.3s ease-in-out;
}

.sidebar.visible {
  left: 0;
}
/* ----- */
#mobileSidebar {
  position: fixed;
  top: 0;
  left: -250px;
  height: 100%;
  width: 250px;
  background-color: white;
  padding-top: 20px;
  transition: 0.5s;
  z-index:1;
}

#mobileSidebar.active {
  left: 0;
}

#content {
  margin-left: 0;
  transition: margin-left 0.5s;
  position: fixed;
  z-index:1;


}

#content.active {
  margin-left: 250px;
}

#sidebarCollapse {
  position: fixed;
  top: 14px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

#sidebarCollapse span {
  background-color: white;
  display: block;
  margin: 5px auto;
  height: 2px;
  width: 25px;
  transition: all 0.3s;
}

#sidebarCollapse span:first-child {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

#sidebarCollapse span:nth-child(2) {
  opacity: 0;
}

#sidebarCollapse span:last-child {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
.list-unstyled{
  margin-top: 40px;
  padding:15px;
}
.sidebar-header{
  text-align: center;
  margin-left:27px;
  
}
.frame .active{
  background-color: #5A4A99;
  width: 80%;
  height:50px;
  border-radius: 10px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
  .anounce-pop {
    position: absolute;
    top: 8%;
    right: 20%;
    background-color: #F9FAFC;
    padding: 23px;
    z-index: 1;
    width: 48%;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    border: 0.5px solid #D7DAE0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p;
    padding: 20px;
}
  .index {
    align-items: center;
    background-color: #fff;
    border-color: #D3CECE;
    border-right-style: solid;
    border-right-width: 1px;
    display: inline-flex;
    flex-direction: column;
    gap: 48px;
    height: 100%;
    width: 90%;
    padding: 138px 30px 123px;
    box-sizing: border-box;
    position: relative;
    margin-left: 13%;
}
  .index .MAKERS-PORTAL {
    color: #2b3674;
    font-family: "Poppins", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 26px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  .index .text-wrapper-3 {
    color: #2b3674;
    font-family: "Poppins", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
}
.index .logo {
  height: 26px;
  left: 3%;
  position: absolute;
  top: 5%;
  width: 100%;
}
.index .frame-2 {
  flex: 0 0 auto;
  position: relative;
  margin-left: 19%;
}
  
}