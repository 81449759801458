.head-details {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

}

.subhead-details {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #897575;

}
.bill{
    display:grid;
}
.bill-col{
    height:80px;
    width: 50%;
}
.item-col{
    border-top: 0.5px solid #D7DAE0;
    border-bottom: 0.5px solid #D7DAE0 ;
    height:40px;
    padding:7px;
    display:flex;
    
}
.amount-col{
    border-bottom: 0.5px solid #D7DAE0 ;
    height:95px;
    padding:7px;
    display:flex;
}
.total-col{
    height:50px;
    padding:7px;
    display:flex;
}
.total-div{
    border-bottom: 0.5px solid #D7DAE0 ;

}
.delete-popup{
    position: absolute;
    top: 59%;
    right: 36%;
    background-color: #FFFFFF;
    padding: 23px;
    z-index: 1;
    width: 304px;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        
  }
  .confirm-button{
    border:none;
   
  }
  .no-button{
    border:none;
   
  }
  .confirm-button:hover{
    color: rgb(5, 24, 46);
    background-color: rgba(5, 24, 46, 0.1); /* Adjust the background color as needed */
    cursor: pointer;
  }
  .no-button:hover{
    color: rgb(4, 11, 23);
    background-color: rgba(4, 11, 23, 0.1); /* Adjust the background color as needed */
    cursor: pointer;
  
  }
.content-details {
    border-radius: 20px;
    border: 1px dashed #B39D9D;
    height: 245px;
    margin-top: 3%;
    width: 100%;
    margin-left: 0%;

}

.plan-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

}

.can-btn {
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    height: 57%;
    width: 50%;
    padding: 8px;
    background: #484444;
    border-radius: 12px;
    gap: 16px;
    box-sizing: border-box;
}

.cancel-div {
    text-align: right;
    padding: 20px;
}

.head-div {
    padding: 20px;
}

.plan-box {
    height: 110px;
    width: 47%;
    border-radius: 14px;
    background: #5A4A99;
    margin-left: 2%;
    display: grid;
    padding: 20px;
}

.plan-box1 {
    height: 110px;
    width: 47%;
    border-radius: 14px;
    background: #5A4A99;
    margin-left: 2%;
    display: flex;
    padding: 20px;
}
.box-heading {
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    color: #B5B5B5;
}
.box-subheading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
}
.box-1{
    display: grid;
    border-right: 1px solid #9BA5B9;
    width: 50%;

}
.box-2{
    display: grid;
    width: 50%;
    margin-left: 6%;

}
.table-details{
    border-top: 1px solid #D3CECE;
    margin-top:4%;
    padding:20px;
}
.tab-head{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    text-align: left;
      
}
.sub-table{
    margin-top:3%;
    margin-bottom: 4%;
}
.cancel-popup{
    position: absolute;
    top: 35%;
    right: 20%;
    background-color: #FFFFFF;
    padding: 23px;
    z-index: 1;
    width: 700px;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p
}
.cancel-head{
    font-family: Inter;
font-size: 20px;
font-weight: 700;

text-align: left;

}

    .yes {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
}
.can-span{

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    
        }
.buttn-div {
    display: flex;
    margin-top: 5%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.yes-button {
    width: 104px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid hsl(0, 13%, 66%);
    background: white;
    text-decoration: none;
    margin-right: 4%;

}
.no-btn{
    width: 99px;
height: 40px;
border-radius: 8px ;  
background:  #5A4A99;
text-decoration: none;
border: none;
}
.close-div{
    display: flex;
}
.close-butn {
    background: none;
    border: none;
  
    font-size: 42px;
    margin-top: -7%;
    cursor: pointer;
    color: #777;
}
.bill-head{
    font-family: Inter;
font-size: 13px;
font-weight: 600;

text-align: left;
color: #1A1C21;

}
.bill-data{
    font-family: Inter;
font-size: 13px;
font-weight: 500;
color: #5E6470;

text-align: left;

}
.invoice-pop{
    position: absolute;
    top: 1%;
    right: 35%;
    background-color: #F9FAFC;
    padding: 23px;
    z-index: 1;
    width: 30%;
    height: auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    border: 0.5px solid #D7DAE0;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p;
    padding:20px;
}
.mak{
    font-family: Poppins;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B3674;
}
.por{
    font-family: Poppins;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B3674;
}
.invoice-content {
    background: #FFFFFF;
    height: 555px;
    width: 90%;
    border: 0.5px solid #D7DAE0;
    border-radius: 12px;
    margin-left: 6%;
    margin-top: 5%;
    padding: 20px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Ensure overlay appears above other elements */
  }
  .bill-data1 {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    color: #5E6470;
    text-align: left;
}
.bill-data2 {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    color: #5E6470;
    text-align: left;
}.bill-data3 {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    color: #5E6470;
    text-align: left;
}
.sub-con{
   
}
@media only screen and (max-width: 600px) {
    .sub-con{
        margin-top: 28%;
        height: 678px;
    }
    .content-details {
        border-radius: 20px;
        border: 1px dashed #B39D9D;
        height: 245px;
        margin-top: 5%;
        width: 100%;
        margin-left: 4%;
    }  
    .invoice-pop {
        position: absolute;
        top: 7%;
        right: 4%;
        background-color: #F9FAFC;
        padding: 23px;
        z-index: 1;
        width: 92%;
        height: auto;
        box-sizing: border-box;
        border-radius: 10px;
        display: grid;
        border: 0.5px solid #D7DAE0;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p;
        padding: 20px;
    }
    
    .bill-head {
        font-family: Inter;
        font-size: 11px;
        font-weight: 600;
        text-align: left;
        color: #1A1C21;
    }
    .bill-data {
        font-family: Inter;
        font-size: 11px;
        font-weight: 500;
        color: #5E6470;
        text-align: left;
    }
    .item-col {
        border-top: 0.5px solid #D7DAE0;
        border-bottom: 0.5px solid #D7DAE0;
        height: 40px;
        padding: 7px;
        display: flex;
        margin-top: -17%;
    }
    .item-data{
        margin-right:12%;
    }
    .bill-data1 {
        font-family: Inter;
        font-size: 8px;
        font-weight: 500;
        color: #5E6470;
        text-align: left;
    }
    .bill-data2 {
        font-family: Inter;
        font-size: 8px;
        font-weight: 500;
        color: #5E6470;
        text-align: left;
    }.bill-data3 {
        font-family: Inter;
        font-size: 8px;
        font-weight: 500;
        color: #5E6470;
        text-align: left;
    }
    .close-butn {
        background: none;
        border: none;
      
        font-size: 29px;
        margin-top: -7%;
        cursor: pointer;
        color: #777;
    }  
    .can-btn {
        color: white;
        font-size: 8px;
        font-family: Inter;
        font-weight: 400;
        height: 57%;
        width: 50%;
        padding: 8px;
        background: #484444;
        border-radius: 12px;
        gap: 16px;
        box-sizing: border-box;
    }
    .billdata-col{
        width:50%;
    }
    .plan-box {
        height: 90px;
        width: 47%;
        border-radius: 14px;
        background: #5A4A99;
        margin-left: 2%;
        display: grid;
        padding: 20px;
    }
    .plan-box1 {
        height: 90px;
        width: 47%;
        border-radius: 14px;
        background: #5A4A99;
        margin-left: 2%;
        display: flex;
        padding: 20px;
    }
    .box-heading {
        font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        text-align: left;
        color: #B5B5B5;
    }
    .quan-col{
        width:45px;
    }
    .subt{
        width:50%;
    }
    .amou{
        width:50%;
    }
    .total-div {
        border-bottom: 0.5px solid #D7DAE0;
        width: 50%;
    }
    .box-subheading {
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;
    }
    .sub-table {
        margin-top: 3%;
        overflow: scroll;
    }
    .view-down{
        display:flex;
    }
    .table-details {
        border-top: 1px solid #D3CECE;
        margin-top: 4%;
        padding: 20px;
        margin-bottom: 1%;
        margin-left: 2%;
    }
    .cancel-popup {
        position: absolute;
        top: 25%;
        right: 5%;
        background-color: #FFFFFF;
        padding: 23px;
        z-index: 1;
        width: 357px;
        height: auto;
        box-sizing: border-box;
        border-radius: 10px;
        display: grid;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p;
    }
    .cancel-head {
        font-family: Inter;
        font-size: 13px;
        font-weight: 700;
        text-align: left;
    }
    .yes {
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
    }
    .yes-button {
        width: 69px;
        height: 29px;
        border-radius: 8px;
        border: 1px solid hsl(0, 13%, 66%);
        background: white;
        text-decoration: none;
        margin-right: 4%;
    }
    .no-btn {
        width: 69px;
        height: 29px;
        border-radius: 8px;
        background: #5A4A99;
        text-decoration: none;
        border: none;
    }
    .can-span{

font-family: Inter;
font-size: 10px;
font-weight: 400;

    }
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
    .sub-con{
        height:1086px;
    }
    .bill-col {
        height: 80px;
        width: 134px;
    }
    .box-subheading {
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;
    }
    .invoice-pop {
        position: absolute;
        top: 8%;
        right: 20%;
        background-color: #F9FAFC;
        padding: 23px;
        z-index: 1;
        width: 48%;
        height: auto;
        box-sizing: border-box;
        border-radius: 10px;
        display: grid;
        border: 0.5px solid #D7DAE0;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29p;
        padding: 20px;
    }
}
@media (min-width: 768px) and (max-width: 768px){
  
    .sub-con{
        height:755px;
    }
    .box-subheading {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;
    }
}
