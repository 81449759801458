.suppliersform{
    display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 28px;
  width:100%;
}
.backbutton{
    justify-content: left;
    text-align: left;
    align-items: left;
}
.header{
    justify-content: left;
    text-align: left;
    align-items: left;
    margin-top:65px;
    inset:0px;
}
.textfield{
    margin-top: 72px;
    justify-content: left;
    box-sizing: border-box;
    text-align: left;
    align-items: left;
}
.add{
    margin-top: 60px;
    width:100%;
    margin-right: 2px;
}
.field{
    border-radius: 10px;
    padding:8px;
    border:1px solid #cac8cc;
    width:100%;
    color: #3E3636;

font-family: Inter;
font-size: 16px;
font-weight: 400;
}
.categoryField{
    width: 100%;
    height: 100%;
    border: 1px solid #cac8cc;
    position: relative;
    border-radius: 10px;
}
.form-label{
    color: #3E3636;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 18px; 
}
.addbuttons {
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    height: 100%;
    width: 100%;
   padding:8px;
    background: #484444;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
}
.react-tel-input .form-control{
    width:100% !important;
    height:45px !important;
    border-radius: 10px !important;
}
.react-tel-input * {
    border-radius: 10px !important;
    font-size: 16px !important;
}
.cat-option{
    border:none;
    text-decoration: none;
    font-size: 11px;
    font-family: "Inter";
    overflow:scroll;
    
}
.cat-selector{
    border: none;
    text-decoration: none;
    margin-left: 78%;
   
}
.close-mark {
    cursor: pointer;
   
  }
  
  .close-mark:hover {
    color: rgb(246, 243, 243); 
  }
  .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 10px !important;
    min-height: 22px;
    padding: 5px;
    position: relative;
}
.chip {
    align-items: center;
    background:#5A4A99 !important;
}
.multiSelectContainer li:hover {
    background:#5A4A99 !important;

}
.highlightOption{
    background:#5A4A99 !important;

}


@media only screen and (max-width: 600px) {
    .suppliersform{
        display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 28px;
      width:100%;
      margin-left:11px;
      margin-top: 100px;

    }
    .react-international-phone-input-container .react-international-phone-country-selector-button {
        border-radius: var(--react-international-phone-border-radius, 4px);
        margin-right: -1px;
        height: 45px !important;
        /* width: 103%; */
        border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
      }
      .react-international-phone-input-container .react-international-phone-input {
        width: 100% !important;
        height: 45px !important;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid #cac8cc;
        font-size: 14px;
        font-family: "Inter";
        font-weight: 500;
    }
    .addbuttons {
        color: white;
        font-size: 14px;
        font-family: Inter;
        font-weight: 400;
        height: 100%;
        width: 115%;
       padding:8px;
        background: #484444;
        border-radius: 12px;
        align-items: center;
        gap: 16px;
        margin-top: 4px;

        box-sizing: border-box;
    }
    .react-tel-input .form-control{
        width:100% !important;
        height:45px !important;
        border-radius: 10px !important;
    }
    .react-tel-input * {
        border-radius: 10px !important;
        font-size: 16px !important;
    }
    .form-labelss {
        color: #696F79;
        font-size: 12px;
        font-family: "Inter";
        font-weight: 500;
        margin-bottom: 1px;
    }
    .categoryField{
        width: 100%;
    height: 100%;
    border: 1px solid rgb(202, 200, 204);
    position: relative;
    border-radius: 10px;

    }
    .cat-option{
        border:none;
        text-decoration: none;
        overflow: hidden;
    }
    .cat-selector{
        border: none;
        text-decoration: none;
        margin-left: 64%;
    }
    
    }
