.loader {
    position: fixed;
    top: 20px;   
     left: 50%;
    transform: translateX(-50%);
   opacity: 1;
   transition: opacity 0.5s ease-in-out;
   transition: opacity 0.5s ease-in-out;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
  
  .loader > div {
    background-color: #fff; 
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  