.client{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 22px;
    width:100%;
  }
  .headers{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .clientheader{
    color: black;
    font-size: 32px;
    font-family:Inter;
    font-weight: 600;
  }
  .add-button {
    text-align: right;
    justify-content:right;
    align-items: flex-end;
    margin-top:3%;
  }
  .export-button{
    text-align: right;
    justify-content:right;
    align-items: flex-end;
    margin-top:3%;
    margin-right: 2%;

  }
  .export{
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    height: 100%;
    width: 148px;
    padding: 8px;
    background: #5A4A99;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    border: none;
    box-sizing: border-box;
  }
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding-top: 36px;
  }
  .search-add-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
  }
  
  .table{
    width:100%;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 36px;
      height: 800px ;
  }
  .done-status{
    display: flex;
    align-items: left;
    justify-content: left;
  }
  .pagination-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
  }
  
  .pagination-buttons button.active {
    cursor: pointer;
    font-size: 14px; 
    font-weight: 400;
    border: 1px #5A4A99 solid;
   }
  .pagination-buttons button.disabled {
    font-weight: 400;
    cursor: pointer;
    border:none; 
  }
  .pagebuttons{
    border: 1px #DFE3E8 solid;
    background-color: #DFE3E8

  }

  .pagebuttons img.active{
border:1px #0f4e93 solid;
box-shadow: #919EAB;
  }
  .numbers{
    padding:10px
  }
  .no-data-found{
    text-align: center;
    margin-top: 8%;
}
  
  @media only screen and (max-width: 600px) {
    .react-international-phone-input-container .react-international-phone-input {
      width: 100% !important;
      height: 30px !important;
      border-radius: 0px 10px 10px 0px !important;
      border: 1px solid #cac8cc;
  }
  .react-international-phone-input-container .react-international-phone-country-selector-button {
    border-radius: var(--react-international-phone-border-radius, 4px);
    margin-right: -1px;
    height: 69% !important;
    /* width: 103%; */
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
    .client {
      padding: 20px; 
      margin-left: 11px ;
      margin-top: 100px;
      height: 690px;

    }
    .clientheader {
      font-size: 24px;
    }
    .table{
      width:100%;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-top: 36px;
        overflow-x: scroll;
    }
    .add-button{
      margin-right: 16%
    }
  
   
    .search,
    .table,
    .done-status {
      text-align: center; 
      align-items: center;
      justify-content: center;
    }
    .export {
      color: white;
      font-size: 14px;
      font-family: "Inter";
      font-weight: 400;
      height: 100%;
      width: 78px;
      padding: 8px;
      background: #5A4A99;
      border-radius: 12px;
      align-items: center;
      gap: 16px;
      text-decoration: none;
      border: none;
      box-sizing: border-box;
  }
   
    .export-button {
      text-align: right;
      justify-content: right;
      align-items: flex-end;
      margin-top: 8%;
      margin-left: 4%;
  }
  .add-button{
    text-align: right;
    justify-content: right;
    align-items: flex-end;
    margin-top: -28%;
    margin-left: -23%;
  }
.search-add-container {
 
}
  }
  @media only screen and (min-width: 600px) and (max-width: 1100px){
    .client {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 22px;
      width: 100%;
      height: 1089px;
  }
  }
  @media (min-width: 768px) and (max-width: 768px) {
    .client {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 22px;
      width: 100%;
      height: 752px;
  }
  .table {
    width: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 36px;
    overflow: scroll;
}
  }