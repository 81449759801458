.frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    
    cursor: pointer;
  }
  
  .frame .frame-wrapper {
    align-items: flex-start;
    border-radius: 24px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    width: 100px;
    padding: 16px 187px 16px 24px;
    position: relative;
  }
  
  .frame .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 31px;
    position: relative;
  }
  
  .frame .home {
    font-family: "Inter", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .traditional {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .frame .inactive {
    margin-right: -7px;
    width: 267px;
  }
  
  .frame .active {
    background-color: #5a4a99;
    width: 260px;
  }
  
  .frame .frame-wrapper.inactive .div {
    margin-right: -42px;
  }
  
  .frame .frame-wrapper.active .div {
    justify-content: center;
    margin-right: -49px;
  }
  
  .frame .frame-wrapper.inactive .home {
    color: #7b7b7b;
  }
  
  .frame .frame-wrapper.active .home {
    color: #ffffff;
  }
  