.dynamic-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    white-space: pre-line;
  }
  
  .heading-row {
    border-radius: 10px;
    background-color:#F5F5F5
  }
  .pop-btn{
    text-align: left;
  }
  .td:hover {
   
    cursor: pointer;
  }
  .tr{
    border-bottom: 1px solid #ddd;

  }
  
  td, th {
    padding: 10px;
    padding-left: 20px;
    border: none; 
    text-align: left; 
  }
  
  th {
    border-bottom: 1px solid #ddd;
    font-family: Inter;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #0F1419;
    background-color:#F5F5F5

  }
  
  td {
    border-bottom: 1px solid #ddd;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color:#000000;
  }
  
  .data-row:hover td {
    background-color: #E1CFFF;
    color: #6926D7; /* Change text color on hover */
  }
  .tick-icon {
    width: 16px; /* Adjust the width as needed */
    height: 16px; /* Adjust the height as needed */
  }
  .done-status-container{
    width: 60%;
    display:flex;
    height:27px;
    padding:4px;
    border-radius: 4px;
    background-color:#CCFFBE;
    justify-content: space-around;
    text-align: center;
  }
  .in-icon {
    width: 17px;
    height: 28px;
}
  .done-status-container2{
    width: 60%;
    display:flex;
    height:27px;
    padding:4px;
    border-radius: 4px;
    background-color:#E1CFFF;
    justify-content: space-around;
    text-align: center;
  }
.green-box{
  display:flex;
  align-items: center;

}
.red-box{
  display:flex;
  align-items: center;
}
.popup-container {
  position: relative;
}

.infocard {
  position: absolute;
  top:4px;
  right:55px;
  background-color:#FFFFFF;
  padding: 23px;
  z-index: 1;
  width:189px;
  height:auto;
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.infobuttons:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
.infobuttons.selected{
  background-color: #e0e0e0;
  cursor: pointer;
}
.popup-container{
  width:100%;
}
.export-icon{
  height: 25px;
}
.export-icon:hover{
  color: rgb(5, 24, 46);
  background-color: rgba(5, 24, 46, 0.1); /* Adjust the background color as needed */
  cursor: pointer;
}
.highlighted-row {
  color: red;
}
.done-status{
  display: flex;
  align-items: center;
  width: 50%;
  padding: 7px;
  border-radius: 3px;
}

@media only screen and (max-width: 768px) {
  .done-status-container {
    padding: 12px; 
    width:100%
  }
  .dynamic-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
   
  }
  .done-status{
    display: flex;
    align-items: center;
    width: 90%;
    padding: 7px;
    border-radius: 3px;
  }
  
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
  .done-status {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px;
    border-radius: 3px;
}
}