.heading-row{
    border-radius: 10px;
    background-color:#F5F5F5
}
.dynamic-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    white-space: pre-line;
  }
  .popup-container {
    position: relative;
  }
  .infocard {
    position: absolute;
    top:4px;
    right:55px;
    background-color:#FFFFFF;
    padding: 23px;
    z-index: 1;
    width:189px;
    height:auto;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .infobuttons:hover{
    background-color: #f0f0f0;
    cursor: pointer;
  }
  .infobuttons.selected{
    background-color: #e0e0e0;
    cursor: pointer;
  }
  .popup-container{
    width:100%;
  }
  .td:hover {
   
    cursor: pointer;
  }
  
  td, th {
    padding: 10px;
    padding-left: 20px;
    border: none; 
    text-align: left; 
  }
  
  th {
    border-bottom: 1px solid #ddd;
    font-family: Inter;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #0F1419;
    background-color:#F5F5F5
  }
  tr{
    border-top: 1px solid #ddd;
  }
  td {
    border-top: 1px solid #ddd;
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color:#000000;
  }
  .table-data{
    border: 1px solid #ddd;
  }
  
  .data-row:hover td {
    background-color: #E1CFFF;
    color: #6926D7; /* Change text color on hover */
  }