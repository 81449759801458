.textfield1 {
    margin-top: 3px;
    justify-content: left;
    box-sizing: border-box;
    text-align: left;
    align-items: left;
}
.field-areas {
    border-radius: 10px;
    padding: 8px;
    border: 1px solid #cac8cc;
    width: 208%;
    font-size: 14px;
    height: 100px;
}
.form-labels {
    color: #3E3636;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.fields {
    border-radius: 10px;
    padding: 8px;
    border: 1px solid #cac8cc;
    width: 100%;
    color: #3E3636;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    height: 43px;
}
.react-international-phone-input-container .react-international-phone-input {
    width: 100% !important;
    height: 43px !important;
    border-radius: 0px 10px 10px 0px !important;
    border: 1px solid  #cac8cc;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
}
.react-international-phone-input-container .react-international-phone-country-selector-button {
    border-radius: var(--react-international-phone-border-radius, 4px);
    margin-right: -1px;
    height: 43px !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.about-us{
    font-family: Inter;
font-size: 16px;
font-weight: 300;
line-height: 30px;
letter-spacing: 0em;
text-align: justified;
padding:11px;

}
.fo-head{
    font-family: Inter;
    font-size: 20px;
    font-weight: 800;
    
    letter-spacing: 0em;
    text-align: justified;
}
.adde{
    margin-right: 2px;
    margin-top: 1px;
    width: 100%;
}

@media only screen and (max-width: 600px) {

    .react-international-phone-input-container .react-international-phone-input {
        width: 100% !important;
        height: 30px !important;
        border-radius: 0px 10px 10px 0px !important;
        border: 1px solid #cac8cc;
    }
    .react-international-phone-input-container .react-international-phone-country-selector-button {
      border-radius: var(--react-international-phone-border-radius, 4px);
      margin-right: -1px;
      height: 69% !important;
      /* width: 103%; */
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
.addformbutton {
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    height: 100%;
    width: 76px;
    padding: 8px;
    background: #484444;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
}
}