body {
    margin: 0;
    font-family: "Oxygen", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-image: url("./utils/images/NewBg.png"); */
    background-color: white;
    /*  */
  }
  #root{
    background-color: white; 
    }
    *, *::before, *::after {
      box-sizing: inherit;
    }
    
    html {
      /* this makes sure the padding and the border is included in the box sizing */
      box-sizing: border-box;
      overflow-x: hidden;
    }
    
    html body {
      padding: 0;
      margin: 0;
      
    }
    *:focus {
      outline: none;
    }
    a:focus, button:focus, input:focus, select:focus, textarea:focus {
      /* Add your custom focus styles here */
      /* border:1px solid #cac8cc; */
      /* Add other styles as needed */
      outline: none;

    }
    Select:focus,Multiselect:focus {
      border: 1px solid #cac8cc;
      box-shadow: 0 0 0 2px white; /* Add a box shadow for emphasis */
      /* Add other styles as needed */
    }
    input:-webkit-autofill { 
      -webkit-background-clip: text;
  }