.screen2 {
    background-color: #5A4A99;
    width: 100%;
    height: 1000px;
    margin: 0px;

}

.craft {
    color: var(--white-100, #FF7A7A);
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ready {
    color: #5A4A99;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.create {
    color: var(--white-100, #F6E96C);
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.manage {
    color: var(--white-100, white);
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.unleash {
    color: var(--white-100, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.free {
    color: #5A4A99;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.screen2-header {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
    width: 100%;

}

.input-email {
    color: #8B8B8B;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px;
    width: 400px;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 3%;
    border: 1px #C6C6C6 solid;
    padding: 15px;

}

.free-trial {
    height: 64px;
    width: 400px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    margin-top: 41px
}

.free-button {
    width: 300px;
    height: 46px;
    background: #5A4A99;
    border: none;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    gap: 8;
    display: inline-flex;
    margin-top: 2%;

}

.home-Icon {
    position: absolute;
    height: 600px;
    top: 22%;
    left: 20%;
}


.screen-icon {  
    position: relative;
    width: 100%;
    height: 100%;
}


.svg1 {
    position: absolute;
    bottom: 92%;
    left: 18%;

}

.dot1 {
    position: absolute;
    bottom: 75%;
    left: 26%;
}


.svg2 {
    position: absolute;
    bottom: 61%;
    left: -2%;
}

.dot2 {
    position: absolute;
    bottom: 37%;
    left: 4%;
}


.svg3 {
    position: absolute;

    bottom: 94%;
    right: 6%;
}


.dot3 {
    position: absolute;
    bottom: 72%;
    right: 13%;

}

.svg4 {
    position: absolute;
    bottom: 63%;
    right: -2%;
}

.dot4 {
    position: absolute;

    bottom: 51%;
    right: 3%;

}

.free-text {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.svg1,
.svg2,
.svg3,
.svg4 {
    width: 120px;
    height: 120px;
}




.screen3 {
    background-color: white;
    width: 100%;
    text-align: center;
    height: 250px;
}

.screen3-header {
    margin-top: 80px;
}

.screen4 {
    background-color: #5A4A99;
    height: 300px;
    margin-top: 40px;
}

.screen4-heading {
    padding: 15px;

}

.footer {
    margin-top: auto;
    padding-bottom: 25px;
    padding-left: 1px;

}

.makers-text {
    color: var(--white-100, #FFF);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.portal-text {
    color: var(--white-100, #FFF);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.footer-content {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 3%
}

.copyright {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 600px) {
   

    .craft {
        color: var(--white-100, #FF7A7A);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .create {
        color: var(--white-100, #F6E96C);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .manage {
        color: var(--white-100, white);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .unleash {
        color: var(--white-100, #FFF);
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .screen3 {
        background-color: white;
        width: 100%;
        text-align: center;
        height: 200px;
    }

    .free {
        color: #5A4A99;
        text-align: center;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .free-trial {
        height: 32px;
        width: 200px;
        background: white;
        border-radius: 8px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        margin-top: 41px
    }

    .svg1,
    .svg2,
    .svg3,
    .svg4 {
        width: 60px;
        height: 38px;
    }

    .free-button {
        width: 150px;
        height: 26px;
        background: #5A4A99;
        border: none;
        margin-bottom: 15px;
        cursor: pointer;
        border-radius: 6px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        gap: 8;
        display: inline-flex;
    }

    .screen2 {
        background-color: #5A4A99;
        width: 100%;
        height: 473px;

    }

    

    .ready {
        color: #5A4A99;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .input-email {
        color: #8B8B8B;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 5px;
        width: 215px;
        height: 30px;
        border: 1px #C6C6C6 solid;
        padding: 15px
    }

    .free-text {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 2%;

    }

    .screen4 {
        background-color: #5A4A99;
        height: 150px;
        margin-top: 40px;
    }

    .makers-text {
        color: var(--white-100, #FFF);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
    }

    .portal-text {
        color: var(--white-100, #FFF);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }

    .footer-content {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .copyright {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .home-Icon {
        position: absolute;
        position: absolute;
        top: 32%;
        /* right: 16%; */
        width: 67%;
        height: 42%;
        left: 16%;
    }
    .dot1,.dot2,.dot3,.dot4{
        height: 153px;
    width: 155px; 
    }
    
    
    .screen-icon {  
        position: relative;
        width: 100%;
        height: 384px;
        top: 12%;
    }
    
    
    .svg1 {
        position: absolute;
        bottom: 82%;
        left: 2%;
    
    }
    
    .dot1 {
        position: absolute;
        bottom: 46%;
        left: 15%;
    }
    
    
    .svg2 {
        position: absolute;
        bottom: 61%;
        left: -8%;
    }
    
    
    .dot2 {
        position: absolute;
        bottom: 28%;
        left: 4%;
    }
    
    
    .svg3 {
        position: absolute;
        bottom: 82%;
        right: -4%;
    }
    
    .dot3 {
        position: absolute;
        bottom: 50%;
        right: 11%
    }
    
    
    
    .svg4 {
        position: absolute;
        bottom: 64%;
        right: -6%;
    }
    
    
    .dot4 {
        position: absolute;
    
        bottom: 36%;
        right: -1%;
    
    }
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
    .home-Icon {
        position: absolute;
        height: 401px;
        top: 41%;
        left: 16%;
    }
    .dot1 {
        position: absolute;
        bottom: 49%;
        left: 26%;
    }
    .svg1 {
        position: absolute;
        bottom: 67%;
        left: 18%;
    }
    .dot2 {
        position: absolute;
        bottom: 20%;
        left: 4%;
    }
    .svg2 {
        position: absolute;
        bottom: 44%;
        left: -2%;
    }
    .dot3 {
        position: absolute;
        bottom: 54%;
        right: 13%;
    }
    .svg3 {
        position: absolute;
        bottom: 73%;
        right: 3%;
    }
    .dot4 {
        position: absolute;
        bottom: 33%;
        right: 3%;
    }
    .svg4 {
        position: absolute;
        bottom: 45%;
        right: -2%;
    }
  
}
@media (min-width: 768px) and (max-width: 768px){
    .home-Icon {
        position: absolute;
        height: 293px;
        top: 41%;
        left: 16%;
      }
      .screen2 {
        background-color: #5A4A99;
        width: 100%;
        height: 813px;
        margin: 0px;
      }
      .svg1, .svg2, .svg3, .svg4 {
        width: 97px;
        height: 106px;
      }
      .svg1 {
        position: absolute;
        bottom: 73%;
        left: 1%;
      }
      .dot3 {
        position: absolute;
        bottom: 41%;
        right: 12%;
      }
      .svg3 {
        position: absolute;
        bottom: 65%;
        right: 3%;
      }
      .svg2 {
        position: absolute;
        bottom: 44%;
        left: -4%;
      }
      .svg4 {
        position: absolute;
        bottom: 45%;
        right: -3%;
      }
      .dot1 {
        position: absolute;
        bottom: 49%;
        left: 12%;
      }
}