
  
  input[type="search"] {
    padding-left: 40px; 
    padding-right:11px;
    background: url('../../icons/Search.svg') 10px center no-repeat; 
    height: 35px; 
    width:100%;
    border: 1px #E8D8D8 solid;
    width: 264px; 
    box-sizing: border-box; 
    border-radius: 16px;
  }
  @media only screen and (max-width: 600px) {
    input[type="search"] {
      padding-left: 40px; 
      padding-right:11px;
      background: url('../../icons/Search.svg') 10px center no-repeat; 
      height: 35px; 
      border: 1px #E8D8D8 solid;
      width: 214px; 
      box-sizing: border-box; 
      border-radius: 16px;
    }
  }