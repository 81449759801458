.suppliesform{
    display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  width:100%;
}
.form-with-borders {
  border-top: 1px solid  #cac8cc;
  padding-top: 27px
}
.product-label{
  display: flex;
  padding:2px;
  text-align: right;
}
.form-labels{
  color: #3E3636;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.form-controls{
  width:50%;
  height:50px;
  border: 1px solid #cac8cc;
  padding:5px;
  text-align: center;
  border-radius: 10px;
}
.form-controlsquan{
  width:30%;
  height:50px;
  border: 1px solid #cac8cc;
  padding:5px;
  text-align: center;
  border-radius: 10px;
}

.units{
  margin-top:30px;
  margin-left: 3px;
  display: flex;

}
.header{
  margin-top:18px;
}
.sup-error{
  color: red;
  font-size: 13px;
  font-family:Inter;
}
.description{
  width:80%;
  height:100px;
  border-radius: 10px;
    padding: 8px;
    border: 1px solid #cac8cc;
   
    font-size: 17px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


@media only screen and (max-width: 600px) {
  .suppliesform{
      display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 22px;
    width:100%;
    margin-left: 11px;
    margin-top: 100px;

  }
  .units{
 
    margin-top: 1px;
    margin-left: 3px;

  }
  .form-labels{
    
    color: #544E4E;

    font-family: Inter;
    font-size: 18.887px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .cat-label{
margin-bottom:12px;
  }
  .header{
    margin-top:17px;
  }
  .add {
    margin-top: 5px;
    width: 100%;
    margin-right: 2px;
}
}