.screen {
  background-image: url("../../icons/image.png");
  background-position: 50% 50%;
  background-size: cover;
  height: 775px;
  border-radius: 0px 0px 794.87px 794.87px;
  width: 100%;
  position:relative

}

.makers {
  color: var(--white-100, #FFF);
  font-family: Poppins;
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
}
.showPassword{
  color: #696F79;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.portal {
  color: var(--white-100, #FFF);
  font-family: Poppins;
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.login-sigin {
  color: #000;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.user-header{
  margin-top:13px;
}
.new-user {
  color: #838384;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

}

.create-account {
  color: #5A4A99;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border: none;
  cursor: po"Inter";
  text-decoration: none;
}

.screen-header {
  height: 41px;
  margin: 15px;
}

.text-container {
  position: absolute;
  top: 71%;
  left: 15%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 50%;
}

.text-container2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 50%;
}



.screen-section {
  box-sizing: border-box;
  padding: 20px;
  margin-top: -21px;
  height: 900px
}

.signin-container {
  background-color: white;
  height: auto;
  padding: 24px;
  box-shadow: 0px 4px 62.70000076293945px rgba(0, 0, 0, 0.09);
  border-radius: 25px;
  width: 408px;
  justify-content: right;
  align-items: right;
  position: absolute;
  left:55%;
  top:33%;
}


.text-head {

  position: absolute;
  top: 60%;
  left: 6%;
    
}

.black-text,
.white-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
}
.elegance{
  background: linear-gradient(90deg, #000 26%, #fff 33%);
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 65px;
  font-weight: 700;
  line-height: 1;

}
.every{
  background: linear-gradient(90deg, #000 57%, #fff 50%);
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 65px;
  font-weight: 700;
  line-height: 1;

}
.black-text {
  background-color: black;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 65px;
  font-weight: 700;
  line-height: 1;
}


.white-text {
  background-color: white;
}




.signup-container {
  background-color: white;
  height: auto;
  padding: 24px;
  box-shadow: 0px 4px 62.70000076293945px rgba(0, 0, 0, 0.09);
  border-radius: 25px;
  width: 408px;
  justify-content: right;
  align-items: right;
  position: absolute;
  left:55%;
  top: 10%;
}

.signup-form {
  width: 100%;
  margin-top: 12px;

}

.elite {
  color: white;
  font-size: 41px;
  font-family: "Inter";
  font-weight: 700;
}

.login-field {
  border-radius: 5px;
  border: 1px solid #cac8cc;
  width: 100%;
  height: 48px;
  color:#494949;
  font-size: 13px;
  font-family: "Inter";
  font-weight: 500;
  padding:21px;
  margin-bottom: 6px ;

}
.error-message{
  color: red;
  font-size: 13px;
  font-family: Inter;
}
.reg-button {
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  width: 100%;
  background: #5A4A99;
  border-radius: 12px;
  border: none;
  cursor: po"Inter";
  height: 48px;
  outline: inherit;
  margin-top: 39px;

}

.landing-label {
  color: #696f79;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
  margin-top: 8px;
}


.react-tel-input .s{
  width: 100% !important;
  height: 48px !important;
  border-radius: 10px !important;
}

.react-tel-input * {
  border-radius: 10px !important;
  font-size: 14px !important;
}

.agree {
  color: #696F79;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.forget {
  color: #838384;
  text-align: right;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  border: none;
  cursor: po"Inter";
  text-decoration: none;
}
.password-field {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.show-button{
  width:50px;
}
.forget{
  cursor: pointer;
                  font-size: 13px;
                  font-weight: 600;
                  color:  #889caf;
                  background: none;
                  border-width: initial;
                  border-style: none;
                  border-color: initial;
                  border-image: initial;
                  margin-top:1px;
                  font-family: "Inter";


}
a:focus{
  outline: none;
}

@media only screen and (max-width: 600px) {
  .screen {
    background-image: url("../../icons/main.png");
    background-position: 50% 50%;
    background-size: cover;
    height: 380px;
    border-radius: 0px 0px 931.87px 1183.87px;
    width: 100%;

  }
  .css-1nmdiq5-menu{
    font-size: 8px !important;
    width:60% !important;
    height:50% !important;

  }
  .css-1u9des2-indicatorSeparator{
    margin-top:2% !important;
  }
  .css-1xc3v61-indicatorContainer{
    margin-top:-12% !important;

  }
  .react-international-phone-input-container .react-international-phone-input {
    width: 100% !important;
    height: 25px !important;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #cac8cc;
    font-size: 8px;
    font-family: "Inter";
    font-weight: 500;
}

.css-1fdsijx-ValueContainer {
  margin-top:-3%;
}
.css-1xc3v61-indicatorContainer {
  margin-top:-17%;

}
.react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin-right: -1px;
  height: 25px !important;
  /* width: 103%; */
  border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

  .makers {
    color: var(--white-100, #FFF);
    font-family: "Poppins";
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
  }
  .signup-form {
    width: 100%;
margin-top:0px;
  }
  .user-header{
    margin-top:0px;
  }
  .portal {
    color: var(--white-100, #FFF);
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .login-sigin {
    color: #000;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .new-user {
    color: #838384;
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .text-head {
 
    position: absolute;
    top: 72%;
    left: 1%;

      
  }
  .elegance{
    background: linear-gradient(90deg, #000 26%, #fff 33%);
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-family: Inter;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
  
  }
  .every{
    background: linear-gradient(90deg, #000 57%, #fff 50%);
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-size: 21px;
    font-family: Inter;

    font-weight: 700;
    line-height: 1;
  
  }
  .black-text {
    background-color: black;
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-size: 21px;
    font-weight: 700;
    font-family: Inter;

    line-height: 1;
  }
  .create-account {
    color: #5A4A99;
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border: none;
    cursor: po"Inter";
    text-decoration: none;
  }

  .landing-label {
    color: #696F79;
    font-size: 8px;
    font-family: "Inter";
    font-weight: 500;
    margin-bottom: 1px;
  }

  .signin-container {
    background-color: white;
    height: auto;
    padding: 14px;
    box-shadow: 0px 4px 62.70000076293945px rgba(0, 0, 0, 0.09);
    border-radius: 14px;
    width: 185px;
    justify-content: right;
    align-items: right;

    position: absolute;
    left: 47%;
    top: 112px
  }

  .login-field {
    border-radius: 5px;
    border: 1px solid #cac8cc;
    width: 100%;
    height: 25px;
    color:black;
    font-size: 8px;
    font-family: "Inter";
    font-weight: 500;
    padding:10px;
    margin-bottom: -4px ;

  }

  .signup-container {
    background-color: white;
    height: auto;
    padding: 10px;
    box-shadow: 0px 4px 62.70000076293945px rgba(0, 0, 0, 0.09);
    border-radius: 14px;
    width: 178px;
    justify-content: right;
    align-items: right;
    position: absolute;
    left: 47%;
    top: 29px
  }
  .error-message{
    color: red;
    font-size: 8px;
    font-family: Inter;
  }
  .screen-header {
    height: 41px;
    margin: 5px;
  }

  .screen-section {
    box-sizing: border-box;
    padding: 20px;
    margin-top: -21px;
    height: 446px
  }

  .text-container {
    position: absolute;
    top: 26%;
    left: 15%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width: 50%;
  }

  .show-button{
   width:11px
  }

  
  
  .react-tel-input .form-control {
    width: 100% !important;
    height: 25px !important;
    border-radius: 5px !important;
  }

  .react-tel-input * {
    border-radius: 5px !important;
    font-size: 8px !important;
  }

  .agree {
    color: #696F79;
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .reg-button {
    text-align: center;
    color: white;
    font-size: 8px;
    font-family: "Inter";
    font-weight: 500;
    width: 100%;
    background: #5A4A99;
    border-radius: 12px;
    border: none;
    cursor: po"Inter";
    height: 25px;
    outline: inherit;
    margin-top: 10px;

  }

  .forget {
    color: #838384;
    text-align: right;
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-decoration: none;
    border: none;
    cursor: po"Inter";
  }
  .showPassword{
    color: #696F79;
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
  }
  .password-toggle{
    display: flex;
    align-items: center;
    margin-top: 1px;
  }
  .toggle-password-checkbox{
    width:8px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
  .text-head {
    position: absolute;
    top: 75%;
    left: 6%;
}
}
@media (min-width: 768px) and (max-width: 768px){
  .elegance {
    background: linear-gradient(90deg, #000 26%, #fff 33%);
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-family: Inter;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
  }
  .every {
    background: linear-gradient(90deg, #000 57%, #fff 50%);
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-size: 34px;
    font-family: Inter;
    font-weight: 700;
    line-height: 1;
}
.black-text {
  background-color: black;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 34px;
  font-weight: 700;
  font-family: Inter;
  line-height: 1;
}
.text-head {
  position: absolute;
  top: 58%;
  left: 6%;
}
.signup-container {
  background-color: white;
  height: auto;
  padding: 10px;
  box-shadow: 0px 4px 62.70000076293945px rgba(0, 0, 0, 0.09);
  border-radius: 14px;
  width: 178px;
  justify-content: right;
  align-items: right;
  position: absolute;
  left: 65%;
  top: 29px;
}
.signin-container {
  background-color: white;
  height: auto;
  padding: 14px;
  box-shadow: 0px 4px 62.70000076293945px rgba(0, 0, 0, 0.09);
  border-radius: 14px;
  width: 185px;
  justify-content: right;
  align-items: right;
  position: absolute;
  left: 65%;
  top: 112px;
}

}