/* TopNavbar.css */

.top-navbar {
    top: 0;
    left: 0;
    width: 100%; /* Set the width to 100% */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    background-color: #fff;
    color: white;
    transition: width 0.3s;
    height: 80px;
  }

  .breadcrumb-div {
    width:100%;
    display: flex;
    text-align: left;
    color: rgba(28, 28, 28, 0.4);
    float: left;
    font-size: 18px;
    font-family: Inter; 
    font-weight: 400;
  }

  .account-icon-div {
    width:100%;
    display: flex;
    text-align: right;
    color: rgba(28, 28, 28, 0.4);
    align-items: end;
    float: right;
    justify-content: right;
  }
  /* Existing styles... */

.popup {
  position: absolute;
  top: 9%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  z-index: 100;
  text-align: left;
 
}
.userguide {
  margin-right: 3%;
  padding-bottom: 1%;
}
.change{
  color: black;
  font-size: 13px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;

}
.nav-sub{
  margin-top: -7%;
}
.change:hover{
  background-color: #d6d1d1;
}
.close-button {
  position: absolute;
  top: -2px;
  right: 5px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #777;
}
.avatar{
  width:55px
}
.logout:hover{
  background-color: #d6d1d1;

}

  @media only screen and (max-width: 768px) {
    .top-navbar {
      top: -5px;
      left: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 41px;
      background-color: #fff;
      color: white;
      transition: width 0.3s;
      height: 80px;
      margin-left: 9px;
      position: fixed;
      width: 100%;
    }
    .avatar{
      width:45px
    }
   
  }