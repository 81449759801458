.pass-row{
    width:80%;
    height:400px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding:20px;
    margin-left:8%;
    margin-top:5%;
}
.pass-head{
text-align: center;
}
.pass-text{
    font-weight: 800;
    font-family: 'Inter';
    font-size: 23px;
}
.pass-label{
    color: #696f79;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}
.new-pass{

}
.input-pass{
    border-radius: 5px;
    border: 1px solid #cac8cc;
    width: 100%;
    height: 48px;
    color:#494949;
    font-size: 13px;
    font-family: "Inter";
    font-weight: 500;
    padding:21px;
    margin-bottom: 6px ;
}
.change-btn{
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    height: 70%;
    width: 148px;
   padding:8px;
   background: #5A4A99;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    border:none;
    box-sizing: border-box;
}
@media only screen and (max-width: 600px) {
    .change-con{
        display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      height: 606px;
      margin-top: 27%;
    }
    .change-btn {
        color: white;
        font-size: 14px;
        font-family: "Inter";
        font-weight: 400;
        height: 77%;
        margin-top: 3%;
        width: 148px;
        padding: 8px;
        background: #5A4A99;
        border-radius: 12px;
        align-items: center;
        gap: 16px;
        text-decoration: none;
        border: none;
        box-sizing: border-box;
    }
    .pass-text {
        font-weight: 800;
        font-family: 'Inter';
        font-size: 17px;
    }
    .pass-label {
        color: #696f79;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1100px) {
     .change-con{
        display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 20px;
      width: 100%;
      height: 890px;
      margin-top: 27%;
    }
}
@media (min-width: 768px) and (max-width: 768px){
    .change-con {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        height: 686px;
        margin-top: 9%;
    }
}