.clientform{
    display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 22px;
  width:100%;
}
.backbutton{
    justify-content: left;
    text-align: left;
    align-items: left;
}
.header{
    justify-content: left;
    text-align: left;
    align-items: left;
    margin-top:65px;
    inset:0px;
}
.textfield{
    margin-top: 72px;
    justify-content: left;
    box-sizing: border-box;
    text-align: left;
    align-items: left;
}
.add{
    margin-top: 60px;
    width:100%;
    margin-right: 2px;
}
.field{
    border-radius: 10px;
    padding:8px;
    border:1px solid #cac8cc;
    width:100%;
    font-size: 17px;
}
.form-labels{
    color: #3E3636;

font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 18px; 
}
.addformbutton {
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    height: 100%;
    width: 100%;
   padding:8px;
    background: #484444;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
}
.field-area{
    border-radius: 10px;
    padding:8px;
    border:1px solid #cac8cc;
    width:100%;
    font-size: 17px;
    height:100px;
    font-family: Inter;
    font-weight:400;
}
.hidden {
    display: none;
  }
  /* Example CSS */
 
  .react-international-phone-input-container{
    width:100% !important;
    height:22px !important;
    border-radius:0px 10px 10px 0px !important;
  }
  /* styles.css */

/* .react-tel-input .form-control{
    width:100% !important;
    height:45px !important;
    border-radius: 10px !important;
}
.react-tel-input * {
    border-radius: 10px !important;
} */
@media only screen and (max-width: 600px) {
    .clientform{
        display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 22px;
      width:100%;
      margin-left: 11px;
      margin-top: 100px;

    }
    .react-tel-input .form-controls{
        width:100% !important;
        height:45px !important;
        border-radius: 10px !important;
    }
    .react-tel-input * {
        border-radius: 10px !important;
    }
    .react-international-phone-input-container .react-international-phone-country-selector-button {
        border-radius: var(--react-international-phone-border-radius, 4px);
        margin-right: -1px;
        height: 45px !important;
        /* width: 103%; */
        border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
      }
      .react-international-phone-input-container .react-international-phone-input {
        width: 100% !important;
        height: 45px !important;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid #cac8cc;
        font-size: 14px;
        font-family: "Inter";
        font-weight: 500;
    }
    .form-labels{
        color: #3E3636;
    
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; 
    }
}
