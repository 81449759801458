.addbutton {
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    height: 100%;
    width: 148px;
   padding:8px;
    background: #484444;
    border-radius: 12px;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    border:none;
    box-sizing: border-box;
}
.button-text{
  font-size: 14px;
}
.infobutton{
  width:100%
}
.delete-popup{
  position: absolute;
  top: 35%;
  right: 36%;
  background-color: #FFFFFF;
  padding: 23px;
  z-index: 1;
  width: 304px;
  height: auto;
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      
}
.confirm-button{
  border:none;
 
}
.no-button{
  border:none;
 
}
.confirm-button:hover{
  color: rgb(5, 24, 46);
  background-color: rgba(5, 24, 46, 0.1); /* Adjust the background color as needed */
  cursor: pointer;
}
.no-button:hover{
  color: rgb(4, 11, 23);
  background-color: rgba(4, 11, 23, 0.1); /* Adjust the background color as needed */
  cursor: pointer;

}
.Password-popup {
  position: absolute;
  top: -12%;
  right: 39%;
  background-color: #FFFFFF;
  padding: 23px;
  z-index: 1;
  width: 594px;
  height: 263px;
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.password-header{
  color: #000;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.password-label{
  color: #696f79;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  margin-top: 2%;
}
.password-input{
  border-radius: 5px;
  border: 1px solid #cac8cc;
  width: 100%;
  height: 48px;
  color:#494949;
  font-size: 13px;
  font-family: "Inter";
  font-weight: 500;
  padding:21px;
  border-radius: 5px;
  border: 1px solid #cac8cc;
  width: 100%;
  height: 48px;
  color: #494949;
  font-size: 13px;
  font-family: "Inter";
  font-weight: 500;
  padding: 21px;
  margin-top: 2%;
}
.button-container{
  text-align: right;
  margin-top: 7%;
}
.pass-btn{
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  width: 90px;
  background: #5A4A99;
  border-radius: 12px;
  border: none;
  cursor: "Inter";
  height: 35px;
  outline: inherit;
 

}
.cancel-btn{
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  width: 90px;
  background: red;
  border-radius: 12px;
  border: none;
  cursor: "Inter";
  height: 35px;
  outline: inherit;
  margin-right: 3%;
  
}
.pass-error{
  color:#494949;
  font-size: 13px;
  font-family: "Inter";
  font-weight: 500;
  color: red;

}
.advice{
  font-size: 11px;
  font-family: "Inter";
}
@media only screen and (max-width: 768px) {
  .addbutton {
    font-size: 8px;
    color: white;
    font-family: "Inter";
    height: 44px;
    width: 74px;
    /* padding: 8px; */
    background: #484444;
    border-radius: 6px;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    border: none;
    box-sizing: border-box;
}
    .button-text{
      font-size: 11px;
    }
    .Password-popup {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 7px 29px 0 #64646f33;
      box-sizing: border-box;
      display: grid;
      height: 288px;
      padding: 23px;
      position: absolute;
      right: 17%;
      top: -12%;
      width: 274px;
      z-index: 1;
  }
  .pass-btn {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    width: 90%;
    background: #5A4A99;
    border-radius: 12px;
    border: none;
    cursor: "Inter";
    height: 35px;
    margin-top: 4%;
    outline: inherit;
}
.button-container {
  text-align: center;
  margin-top: 7%;
}
  }