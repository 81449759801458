.suppliers{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 22px;
    width:100%;

  }
  .headers{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .suppliersheader{
    color: black;
    font-size: 32px;
    font-family:Inter;
    font-weight: 600;
  }
  .add-button {
    text-align: right;
    justify-content:right;
    align-items: flex-end;
    margin-top:3%;
  }
  .search {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding-top: 36px;
  }
  
  .table{
    width:100%;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 36px;
      height: 800px;
      overflow: scroll;
      scrollbar-width: none;

  }
  
  .done-status{
    display: flex;
    align-items: left;
    justify-content: left;
  }
  .no-data-found{
    text-align: center;
    margin-top: 8%;
}
.export-button{
  text-align: right;
  justify-content:right;
  align-items: flex-end;
  margin-top:3%;
  margin-right: 2%;

}
.export{
  color: white;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  height: 100%;
  width: 148px;
  padding: 8px;
  background: #5A4A99;
  border-radius: 12px;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  border: none;
  box-sizing: border-box;
}
  @media only screen and (max-width: 600px) {
    .suppliers {
      padding: 20px; 
      margin-left:11px;
      margin-top: 100px;
      height: 690px;

    }
    .suppliersheader {
      font-size: 24px;
    }
    .table{
      width:100%;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-top: 36px;
        overflow-x: scroll;
    }
    .table::-webkit-scrollbar {
      display: none;
    }
    
    .table {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .add-button,
    .search,
    .table,
    .done-status {
      text-align: center; 
      align-items: center;
      justify-content: center;
    }
    .export {
      color: white;
      font-size: 14px;
      font-family: "Inter";
      font-weight: 400;
      height: 100%;
      width: 78px;
      padding: 8px;
      background: #5A4A99;
      border-radius: 12px;
      align-items: center;
      gap: 16px;
      text-decoration: none;
      border: none;
      box-sizing: border-box;
  }
  .export-button {
    text-align: right;
    justify-content: right;
    align-items: flex-end;
    margin-top: 8%;
    margin-left: 4%;
}
.add-button{
  text-align: right;
  justify-content: right;
  align-items: flex-end;
  margin-top: -28%;
    margin-left: -23%;
}
  }
  @media only screen and (min-width: 600px) and (max-width: 1100px){
    .suppliers {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 22px;
      width: 100%;
      height: 1089px;
  }
  .table {
    width: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 36px;
    overflow: scroll;
}
.table::-webkit-scrollbar {
  display: none;
}

.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  }
  @media (min-width: 768px) and (max-width: 768px) {
    .suppliers {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 22px;
      width: 100%;
      height: 752px;
  }
  .table {
    width: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-top: 36px;
    overflow: scroll;
}
.table::-webkit-scrollbar {
  display: none;
}

.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  }