.footer-row {
    background-color: #5A4A99;
    height: 195px;
    
    border-radius: 0px 0px 32px 32px;
    display: flex;
    position: relative;


}
.foot-header {
    padding: 21px;
}
.foot-link {
    display: grid;
    padding: 21px;
    text-align: left;
    cursor: pointer;

}
.foot-copy {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1%;
}

.footer-text {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 3%;
}

.links {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 30%;
    margin-top: 2%;
    cursor: pointer;
}
.links:hover{
    
    color: #70aad0;
  } 
  .linkes:hover{
    
    color: #70aad0;
  }
.links1:hover {
    
    color: #70aad0;
  }
  .links2:hover {
    
    color: #70aad0;
  }
.copy {
    text-align: center;
    border-top: 1px solid #ddd;
    padding: 8px;
}
.close-icon{
    height:15px;
}
.display{
    position: absolute;
    right: 20%;
    top: -256%;

    background-color: #FFFFFF;
    padding: 35px;
    z-index: 1;
    width: 800px;
    height: 600px;
    box-sizing: border-box;
    border-radius: 30px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.content{
    overflow-y: scroll;
    margin-top:2%;
    
}


.content::-webkit-scrollbar {
    display: none;
}

.content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.display-heading{
    margin-top:1%;
}
.heading-font{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    color:#5A4A99;
}
.footer1{
    display: grid;
    padding: 21px;
    padding-left: 6%;
    text-align: left;
}
.links1{
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 50%;
    margin-top: 2%;
    cursor: pointer;

}
.links2{
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 50%;
    margin-bottom:20%;
    cursor: pointer;


}
.linkes {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 8%;
    margin-top: 2%;
    cursor: pointer;

}
.links {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 8%;
    margin-top: 2%;
    cursor: pointer;

}
.close-display {
    position: absolute;
    top: 5%;
    right: 6%;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #777;
}
.subheading-font{
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    color: #5A4A99;
    margin-left: 1%;
}

.foot-copy {

    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0%;


}
.footer-text {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 3%;
}
.heading-font{
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    color:#5A4A99;
}


@media only screen and (max-width: 600px){
    .field-areas {
        border-radius: 10px;
        padding: 8px;
        border: 1px solid #cac8cc;
        width: 103%;
        font-size: 17px;
        height: 63px;
    }
    .footer1 {
        display: grid;
        padding: 21px;
        padding-left: 5%;
        text-align: left;
    }
    .about-us {
        font-family: Inter;
        font-size: 10px;
        font-weight: 300;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: justified;
    }
    .linkes {
        color: var(--white-100, #FFF);
        /* text-decoration: none; */
        font-family: Inter;
        font-size: 8px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        border: none;
        margin-left: 8%;
        margin-top: 2%;
        cursor: pointer;
    }
    .fo-head {
        font-family: Inter;
        font-size: 16px;
        font-weight: 800;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: justified;
    }
    .makers-text {
        color: var(--white-100, #FFF);
        font-family: Poppins;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
    }
    .portal-text {
        color: var(--white-100, #FFF);
        font-family: Poppins;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
    .foot-header {
        padding: 10px;
    }
    .footer-text {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 7px;
        font-style: normal;
        font-weight: 400;
        margin-top: 3%;
    }
    .foot-copy {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1%;
    }
    .footer-row {
        background-color: #5A4A99;
        height: 155px;
        border-radius: 0px 0px 32px 32px;
    }
    .copy {
        text-align: center;
        border-top: 1px solid #ddd;
        margin-top: -1%;
    }
    .foot-copy {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1%;
    }
.footer-text {
    color: var(--white-100, #FFF);
    font-family: Inter;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    margin-top: 3%
}
.footer1 {
    display: grid;
    padding: 21px;
    padding-left: 17%;

    text-align: left;
    width: 40%;
    margin-left: -26%;

}
.foot-link {
    display: grid;
    padding: 24px;
    text-align: left;
    width: 31%;
    margin-left: -12%;
}
.links1 {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 8px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    
    margin-top: 4%;
}
.links2 {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 8px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
   
    margin-bottom: 20%;
}
.links {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 8px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    
}

.display {
    position: absolute;
    top: -411%;
    /* bottom: -21%; */
    right: 8%;
    background-color: #FFFFFF;
    padding: 23px;
    z-index: 1;
    width: 341px;
    height: 568px;
    box-sizing: border-box;
    border-radius: 30px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.close-display {
    position: absolute;
    top: 3%;
    right: 6%;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #777;
}
.heading-font {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    color: #5A4A99;
}
}
@media only screen and (min-width: 600px) and (max-width: 1100px){
.display {
    position: absolute;
    top: -307%;
    right: 17%;
    background-color: #FFFFFF;
    padding: 23px;
    z-index: 1;
    width: 635px;
    height: 600px;
    box-sizing: border-box;
    border-radius: 30px;
    display: grid;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.footer1 {
    display: grid;
    padding: 21px;
    text-align: left;
    width: 22%;
    margin-left: -3%;
}
.linkes, .links {
    border: none;
    color: #fff;
    color: var(--white-100, #fff);
    cursor: pointer;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 8%;
    margin-top: 2%;
    text-decoration: none;
}
.links2 {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 9px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 60%;
    margin-bottom: 20%;
}
.links {
    color: var(--white-100, #FFF);
    text-decoration: none;
    font-family: Inter;
    font-size: 9px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    border: none;
    margin-left: 8%;
    margin-top: 2%;
}
.foot-link {
    display: grid;
    padding: 21px;
    text-align: left;
    margin-left: -3%;
}
}
@media (min-width: 768px) and (max-width: 768px) {
    .display {
        position: absolute;
        top: -302%;
        right: 9%;
        background-color: #FFFFFF;
        padding: 23px;
        z-index: 1;
        width: 635px;
        height: 600px;
        box-sizing: border-box;
        border-radius: 30px;
        display: grid;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .footer1 {
        display: grid;
        padding: 21px;
        padding-left: 5%;
        text-align: left;
    }
    .linkes {
        color: var(--white-100, #FFF);
        text-decoration: none;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        border: none;
        margin-left: 8%;
        margin-top: 2%;
        cursor: pointer;
    }
    .footer-text {
        color: var(--white-100, #FFF);
        font-family: Inter;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        margin-top: 3%;
    }
    .footer-row {
        background-color: #5a4a99;
        border-radius: 0 0 32px 32px;
        display: flex;
        height: 219px;
        position: relative;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1336px) {
    .footer-row {
        background-color: #5a4a99;
        border-radius: 0 0 32px 32px;
        display: flex;
        height: 219px;
        position: relative;
    }
}