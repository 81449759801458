.snackbar {
    position: fixed;
    top: 20px;   
     left: 50%;
    transform: translateX(-50%);
    padding: 16px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .success {
    background-color:  #6926D7; 
  }
  
  .errors {
    background-color: #dc3545; 
  }
  
  .show {
    opacity: 1;
  }
  
  .hide {
    opacity: 0;
  }
  