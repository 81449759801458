.main{
    height:100%;
    width:100%;
   
    box-sizing: border-box;

}
@media (min-width: 768px) and (max-width: 768px){
.col-md-9 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 10%;

}
}