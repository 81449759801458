.form-table{
    width:100%;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding-top: 36px;
      overflow-x: scroll;

  }
  .OrderForm{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 22px;
    width:100%;

  }
  .header{
    justify-content: left;
    text-align: left;
    align-items: left;
    margin-top:25px;
    inset:0px;
}
.form-labels{
  color: #3E3636;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 18px; 
}
.field{
  border-radius: 10px;
  padding:8px;
  border:1px solid #cac8cc;
  width:100%;
  color: #3E3636;
font-family: Inter;
font-size: 16px;
font-weight: 400;
}
.quantity{
  display: grid;
}
.field-quantity{
  border-radius: 10px;
  padding:8px;
  border:1px solid #cac8cc;
  width:100%;
  color: #3E3636;
font-family: Inter;
font-size: 16px;
font-weight: 400;
}
.desc{
  border-radius: 10px;
  padding:8px;
  border:1px solid #cac8cc;
  width:80%;
  height:80px;
  color: #3E3636;
font-family: Inter;
font-size: 16px;
font-weight: 400;
}
.pro-desc{
  border-radius: 10px;
  padding:8px;
  border:1px solid #cac8cc;
  width:80%;
  height:50px;
  color: #3E3636;
font-family: Inter;
font-size: 16px;
font-weight: 400;
}
.addproduct{
  margin-top: 60px;
  width:100%;
  margin-right: 2px;
  border-bottom: 1px solid #ddd; 

}
.add{
  margin-top: 60px;
  width:100%;
  margin-right: 2px;
}
.addformbutton {
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  height: 100%;
  width: 100%;
 padding:8px;
  background: #484444;
  border-radius: 12px;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;

}
.savebutton{
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  height: 100%;
  width: 100%;
 padding:8px;
  background: #4005A0;
  border-radius: 12px;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}
.create-btn {
  margin-top: 2%;
  margin-left: 2%;
}
.delete-btn{
  margin-top: 2%;

}
.create-new{
  color: white;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  height:34px;
  width: 104px;
 padding:8px;
  background: #4005A0;
  border-radius: 12px;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}
.form-with-border {
  padding-bottom: 15px; /* Adjust this value based on your design preferences */
}
.material-row{
  margin-top:2%
}
.custom-datepicker {
  height: 40px;
  padding:13px;
  border:1px solid #cac8cc;
  border-radius: 10px;
  width: 100%;
  color: #3E3636;
font-family: Inter;
font-size: 16px;
font-weight: 400;
}
.done-status {
  display: flex;
  align-items: left;
  justify-content: left;
  width: 109px;
  font-size: 12px;
}
.product-labels{
 

}
.material-label{
 
}
@media only screen and (max-width: 768px) {
  .material-label{
    margin-bottom: 28px;
  }
  .product-labels{
    margin-bottom: 28px;

  }
  .OrderForm{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 22px;
    width:100%;
    margin-left: 11px;
    margin-top: 100px;

  }
  .form-labels{
    color: #3E3636;
  
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; 
  }
}
@media only screen and (min-width: 600px) and (max-width: 1100px){
  .create-btn {
    margin-top: 2%;
    margin-left: 7%;
}
}